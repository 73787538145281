import React, {useState, useEffect} from 'react';
import {Avatar, Box, Menu, MenuButton, MenuList, Text} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';

import {login} from '../auth';

export const getGreeting = (firstName: string, hours: Number) => {
    let greet;
    if (hours < 12) {
        greet = 'Morning';
    } else if (hours >= 12 && hours <= 17) {
        greet = 'Afternoon';
    } else if (hours >= 17 && hours <= 24) {
        greet = 'Evening';
    }

    return `Good ${greet}, ${firstName}`;
};

export const UserInfo = () => {
    const [greeting, setGreeting] = useState('');
    const [firstName, setFirstName] = useState('');

    useEffect(() => {
        (async () => {
            const loginResponse = await login();
            const hours = new Date().getHours();
            const firstName = loginResponse?.account?.name?.split(' ')[1] ?? '';
            const greeting = getGreeting(firstName, hours);
            setGreeting(greeting);
            setFirstName(firstName);
        })();
    }, []);

    return (
        <Box marginRight="1rem">
            <Menu>
                <MenuButton>
                    <Box display="flex" alignItems="center">
                        <Text
                            color="white"
                            fontSize="1rem"
                            lineHeight="1.5rem"
                            textAlign="right"
                            fontFamily="Arial"
                            fontWeight="400"
                        >
                            {greeting}
                        </Text>
                        <ChevronDownIcon boxSize={6} color="white" />
                        <Avatar name={firstName} width="48px" ml="0.5rem" />
                    </Box>
                </MenuButton>
                {/* <MenuList
                    bg="#ffffff"
                    color="#404040"
                    border="#282a2e"
                    borderRadius="2"
                    className="user-menu-list"
                ></MenuList> */}
            </Menu>
        </Box>
    );
};
