import * as React from 'react';
import {Piral} from 'piral';
import {render} from 'react-dom';
import {setup} from './setup';

const run = async () => {
    const piralInstance = await setup();
    render(
        <React.Suspense fallback={<div>loading...</div>}>
            <Piral instance={piralInstance} />
        </React.Suspense>,
        document.querySelector('#app')
    );
};

run();
