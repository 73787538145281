import * as React from 'react';
import {Link} from 'react-router-dom';
import {ComponentsState, ErrorComponentsState, SwitchErrorInfo} from 'piral';
import {Menu} from './components/NavigationMenu';
import {DashboardContainer, DashboardTile} from './components/Dashboard';
import {LayoutContainer} from './components/LayoutContainer';

export const errors: Partial<ErrorComponentsState> = {
    not_found: () => (
        <div>
            <p className="error">Could not find the requested page. Are you sure it exists?</p>
            <p>
                Go back <Link to="/">to the dashboard</Link>.
            </p>
        </div>
    )
};

export const layout: Partial<ComponentsState> = {
    ErrorInfo: props => (
        <div>
            <h1>Error</h1>
            <SwitchErrorInfo {...props} />
        </div>
    ),
    DashboardContainer,
    DashboardTile,
    Layout: LayoutContainer,
    MenuContainer: Menu
};
