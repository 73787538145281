import {createInstance, createStandardApi} from 'piral';
import {PiletBundleEntry} from 'piral-base';
import {AuthenticationResult} from '@azure/msal-browser';
import {layout, errors} from './layout';
import {login, createMsalApi} from './auth';

// change to your feed URL here (either using feed.piral.cloud or your own service)
const feedUrl = '/feed-service';

export const getPilets = async (token: AuthenticationResult) => {
    try {
        const response = await fetch(`${feedUrl}/api/pilets`, {
            headers: {
                Authorization: `Bearer ${token?.idToken}`
            }
        });
        const {items} = await response.json();
        const pilets = items.map((pilet: PiletBundleEntry) => {
            const {link: oldLink} = pilet;
            const piletURl = new URL(oldLink);
            const link = `${feedUrl}${piletURl.pathname}`;
            return {...pilet, link};
        });
        return pilets;
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const setup = async () => {
    const loginResponse = await login();
    console.log('------ User Token ------', loginResponse?.token);
    const instance = createInstance({
        state: {
            components: layout,
            errorComponents: errors
        },
        plugins: [createMsalApi(), ...createStandardApi()],
        async requestPilets() {
            return getPilets(loginResponse?.token as AuthenticationResult);
        }
    });

    return instance;
};
