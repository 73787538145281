import {Configuration} from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: String(process.env.AZURE_AD_CLIENT_ID), // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};
