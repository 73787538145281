import {
    PublicClientApplication,
    IPublicClientApplication,
    AuthenticationResult,
    AccountInfo
} from '@azure/msal-browser';
import type {} from 'piral-core';
import {PiralPlugin} from 'piral-core';

import {msalConfig} from './config';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on rerenders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
let msalInstance: PublicClientApplication;

export const getMsalInstance = () => {
    if (!msalInstance) {
        msalInstance = new PublicClientApplication(msalConfig);
    }
    return msalInstance;
};

/**
 * Creates new Pilet API extensions for the integration of MSAL.
 */

export interface PiralMsalApi {
    getMsalInstance(): IPublicClientApplication;
}

declare module 'piral-core/lib/types/custom' {
    // eslint-disable-next-line
    interface PiletCustomApi extends PiralMsalApi {}
}

export function createMsalApi(): PiralPlugin<PiralMsalApi> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return context => ({
        getMsalInstance() {
            return msalInstance;
        }
    });
}

export const loginRequest = {
    scopes: ['openid', 'User.Read'],
    redirectStartPage: window.location.href
    // Keeping below as a refrence in case we need it later.
    // onRedirectNavigate?: (url: string) => boolean | void;
};

export type LoginResponse = {
    token?: AuthenticationResult;
    account?: AccountInfo;
} | null;

export const login = async (getAuthInstance = getMsalInstance): Promise<LoginResponse> => {
    const authInstance = getAuthInstance();
    await authInstance.handleRedirectPromise();
    const accounts = authInstance.getAllAccounts();

    if (accounts.length === 0) {
        await authInstance.loginRedirect(loginRequest);
        return null;
    }
    const [account] = accounts;
    const token = await authInstance.acquireTokenSilent({
        scopes: ['User.Read'],
        account
    });
    return {token, account};
};
