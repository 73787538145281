import {theme} from '@ccap/ccap-ui-react';
import React, {ReactNode} from 'react';
import {Menu} from 'piral';
import {ChakraProvider, Box} from '@chakra-ui/react';
import {createGlobalStyle} from 'styled-components';

import {Header} from './Header';

type LayoutContainerProps = {
    children?: ReactNode;
};

const GlobalStyles = createGlobalStyle`
	html, body {
		margin: 0;
		padding: 0;
	}
	html, body, #react-root {
		height: 100%;
	}
	.app-container {
      background: #F5F5F5;
        height: 100%;
        min-height: 100vh;
        margin: 0 auto;
		display: grid;
		grid-template-columns: 6.5rem 1fr;
		grid-template-rows: 4.5rem 1fr auto;
		grid-template-areas:
		"header header"
	    "sidebar main";
		> main {
          display: flex;
          align-self: flex-start;
          justify-content: center;
          height: 100%;
          overflow-y: auto;
          margin: 0;
          padding: 3rem 3.5rem 3.5rem 3.5rem;
        }
        > aside {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          width: 6.5rem;
          background: #FAFAFA;
          box-shadow: 0 8px 8px rgba(64, 64, 64, 0.25);
          /* Sidebar always on top */
          z-index: 9999;
        }
	}
	header {
		grid-area: header;
		padding: 0 !important;
		height: 4.5rem; /** TODO : replace with a size from theme */
        z-index: 200;

		> * {
			width: 100%;
		}
	}
	main {
		grid-area: main;
        .app-content {
            width: 100%;
            padding: 2rem;
            align-self: flex-start;
            overflow-x: hidden;
            overflow-y: hidden;

            > div {
              margin: 0 !important;
            }
		}
	}
	aside {
      grid-area: sidebar;
      height: 100%;
      display: flex;
      align-items: flex-start;
	}
`;

export const LayoutContainer = ({children}: LayoutContainerProps) => (
    <>
        <GlobalStyles />
        <ChakraProvider theme={theme}>
            <main>
                <div className="app-container">
                    <Header />
                    <Menu type="general" />
                    <Box className="app-content">{children}</Box>
                </div>
            </main>
        </ChakraProvider>
    </>
);
