import React, {ReactNode} from 'react';

type DashboardContainerProps = {
    children?: ReactNode;
};

type DashboardTileProps = {
    columns?: string | number;
    rows?: string | number;
    children?: ReactNode;
};

export const DashboardTile = ({columns, rows, children}: DashboardTileProps) => (
    <div className={`tile cols-${columns} rows-${rows}`}>{children}</div>
);

export const DashboardContainer = ({children}: DashboardContainerProps) => <>{children}</>;
