import React from 'react';
import {NavLink} from 'react-router-dom';
import {VStack, Box, Icon} from '@chakra-ui/react';
import {RepeatIcon, SearchIcon} from '@chakra-ui/icons';
import {FaHome} from 'react-icons/fa';

export const Menu = () => (
    <VStack
        bg="#FAFAFA"
        flexDirection="column"
        display="flex"
        alignSelf="flex-start"
        height="100%"
        width="6.5rem"
        boxShadow="0px 8px 8px rgba(64, 64, 64, 0.25)"
        paddingTop="4rem"
    >
        <NavLink to="" exact>
            <Box
                h="40px"
                display="flex"
                flexDirection="column"
                alignSelf="center"
                marginBottom="3rem"
                justifyContent="center"
            >
                <Icon as={FaHome} boxSize={10} alignSelf="center" marginBottom="8px" />
                <p
                    style={{
                        fontSize: '0.875rem',
                        font: 'Arial',
                        fontWeight: 'bold',
                        lineHeight: '1.25rem',
                        letterSpacing: '0',
                        textAlign: 'center'
                    }}
                >
                    Home
                </p>
            </Box>
        </NavLink>
        <NavLink to={'/reprocessclaims'} exact>
            <Box
                h="40px"
                display="flex"
                flexDirection="column"
                alignSelf="center"
                marginBottom="3rem"
                justifyContent="center"
            >
                <RepeatIcon boxSize={8} alignSelf="center" marginBottom="8px" />
                <p
                    style={{
                        fontSize: '0.875rem',
                        font: 'Arial',
                        fontWeight: 'bold',
                        lineHeight: '1.25rem',
                        letterSpacing: '0',
                        textAlign: 'center'
                    }}
                >
                    ReProcess
                </p>
            </Box>
        </NavLink>
        <NavLink to="/claimlookup" exact>
            <Box
                h="40px"
                display="flex"
                flexDirection="column"
                alignSelf="center"
                marginBottom="3rem"
                justifyContent="center"
            >
                <SearchIcon boxSize={8} alignSelf="center" marginBottom="8px" />
                <p
                    style={{
                        fontSize: '0.875rem',
                        font: 'Arial',
                        fontWeight: 'bold',
                        lineHeight: '1.25rem',
                        letterSpacing: '0',
                        textAlign: 'center'
                    }}
                >
                    Claim Lookup
                </p>
            </Box>
        </NavLink>
    </VStack>
);
